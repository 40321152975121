import { MessageBoxActionTypes } from "../actions/MessageBox_Actions";

export interface MessageBoxState {
    show: boolean;
    title: string;
    className: string;
    content: string;
    isConfirmation: boolean;
    callBackFunction: any;
};

const initialState: MessageBoxState = {
    show: false,
    title: "",
    className: "",
    content: "",
    isConfirmation: false,
    callBackFunction: null
};

export const MessageBox_Reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case MessageBoxActionTypes.ShowMessageBoxType:
            return {
                show: action.payload.show,
                title: action.payload.title,
                className: action.payload.className,
                content: action.payload.content,
                isConfirmation: action.payload.isConfirmation,
                callBackFunction: action.payload.callBackFunction
            };
        case MessageBoxActionTypes.ResetMessageBoxType:
            return initialState;
        default:
            return state;
    }
};