import { DigitalAssetLybraryActionTypes } from "../actions/DigitalAssetLibrary_Actions";

export interface ActionType {
    assetType: string[];
    fileType: string[];
    dimension: string[];
    channel: string[];
};

const initialState: ActionType = {
    assetType: [],
    fileType: [],
    dimension: [],
    channel: [],
};

export const DigitalAssetLibrary_Reducer = (state = initialState, action: any) => {
    const { payload } = action;
    switch (action.type) {
        case DigitalAssetLybraryActionTypes.GetActionType:
            return {
                ...state,
                assetType: payload.assetTypes,
                fileType: payload.fileTypes,
                dimension: payload.dimensions,
                channel: payload.channels,
            }
           
        case DigitalAssetLybraryActionTypes.GetLibraryList:
            return {
                ...state,
                imageList: [...payload],
            }
        default:
            return state;
    }
};

//const InitialState = { assetType: [], fileType: [], dimension: [], channel: [] };

// export const DigitalAssetLibrary_Reducer = (state = InitialState, action) => {
//     const { payload } = action;
//     switch (action.type) {
//         case GET_ACTIONTYPE:
//             return {
//                 ...state,
//                 assetType: payload.assetTypes,
//                 fileType: payload.fileTypes,
//                 dimension: payload.dimensions,
//                 channel: payload.channels,
//             }
//         case GET_LIBRARYLIST:
//             return {
//                 ...state,
//                 imageList: [...payload],
//             }
//         default:
//             return state
//     }
// }

