let IsProduction = false;
let API_VERSION = "1.0";
let WEB_URL = 'URLMissing';
let BACKEND_URL = 'URLMissing';
let LOGIN_URL = "URLMissing";

const hostname = window && window.location && window.location.hostname;

switch (hostname) {
    case 'zandra.keellssuper.net':
        IsProduction = true;
        WEB_URL = "https://zandra.keellssuper.net";
        LOGIN_URL = "https://xena.keellssuper.net";
        BACKEND_URL = 'https://zandraback.keellssuper.net';
        break;
    case 'zandraqa.keellssuper.net':
        IsProduction = true;
        WEB_URL = "https://zandraqa.keellssuper.net";
        LOGIN_URL = "https://xena-qa.keellssuper.net";
        BACKEND_URL = 'https://zandraqabackend.keellssuper.net';
        break;
    case 'localhost':
        IsProduction = false;
        WEB_URL = "http://localhost:3000";
        LOGIN_URL = "http://localhost:3339";
        BACKEND_URL = 'http://localhost:52316';
        break;
    default:
        console.error('Hosted server name is missing in the environment-config file.');
        console.error(hostname);
        break;
}

export { IsProduction, API_VERSION, WEB_URL, LOGIN_URL, BACKEND_URL };