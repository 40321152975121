import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

/*-- Import required components --*/
import LoadingScreen from './components/common/loadingScreen/LoadingScreen';
const DefaultLayout = React.lazy(() => import('./defaultLayout/DefaultLayout'));

function App() {

  function loading(): any {
    return <LoadingScreen isVisible={true} />
  }

  return (
    <BrowserRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route path="/"  component={DefaultLayout} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
