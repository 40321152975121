import { LoadingScreenActionTypes } from "../actions/LoadingScreen_Actions";

export interface LoadingScreenState {
    isVisible: boolean;
};

const initialState: LoadingScreenState = {
    isVisible: false
};

export const LoadingScreen_Reducer = (state = false, action: any) => {
    switch (action.type) {
        case LoadingScreenActionTypes.ShowLoadingScreenType:
            return true;
        case LoadingScreenActionTypes.HideLoadingScreenType:
            return false;
        default:
            return state;
    }
};