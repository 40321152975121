import { UserSessionState } from "../reducers/UserSession_Reducer";

export enum UserSessionActionsTypes {
    AddUserSessionType = "ADD_USER_SESSION",
    AddGrantedMenuType = "ADD_GRANTED_MENU",
    AddGrantedAppsType = "ADD_GRANTED_APPS",
    AddGrantedLocationsType = "ADD_GRANTED_LOCATIONS",
    ResetUserSessionType = "RESET_USER_SESSION"
}

export function addUserSession(userSession: UserSessionState) {
    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: UserSessionActionsTypes.AddUserSessionType,
                payload: userSession
            });
            resolve(true)
        });
    }
};

export function addGrantedMenu(grantedMenu: []) {
    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: UserSessionActionsTypes.AddGrantedMenuType,
                payload: grantedMenu
            });
            resolve(true)
        });
    }
};

export function addGrantedApps(grantedApps: []) {
    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: UserSessionActionsTypes.AddGrantedAppsType,
                payload: grantedApps
            });
            resolve(true)
        });
    }
};

export function addGrantedLocations(grantedLocations: []) {
    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: UserSessionActionsTypes.AddGrantedLocationsType,
                payload: grantedLocations
            });
            resolve(true)
        });
    }
};

export function resetUserSession() {
    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: UserSessionActionsTypes.ResetUserSessionType
            });
            resolve(true)
        });
    }
};
