import { MessageBoxState } from "../reducers/MessageBox_Reducer";

export enum MessageBoxActionTypes {
    ShowMessageBoxType = "SHOW_MESSAGE_BOX",
    ResetMessageBoxType = "RESET_MESSAGE_BOX",
}

export const showMessageBox = (messageBoxState: MessageBoxState) => ({
    type: MessageBoxActionTypes.ShowMessageBoxType,
    payload: messageBoxState
});

export function resetMessageBox() {
    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: MessageBoxActionTypes.ResetMessageBoxType
            });
            resolve(true)
        });
    }
};
