export enum LoadingScreenActionTypes {
    ShowLoadingScreenType = "SHOW_LOADING_SCREEN",
    HideLoadingScreenType = "HIDE_LOADING_SCREEN",
}

export const showLoadingScreen = () => ({
    type: LoadingScreenActionTypes.ShowLoadingScreenType
});

export const hideLoadingScreen = () => ({
    type: LoadingScreenActionTypes.HideLoadingScreenType
});
