import { AzureConfigurationState } from "../reducers/AzureConfiguration_Reducer";

export enum AzureConfigurationActionsTypes {
    AddAzureConfigurationType = "ADD_AZURE_CONFIGURATION",
    ResetAzureConfigurationType = "RESET_AZURE_CONFIGURATION"
}

export function addAzureConfiguration(azureConfiguration: AzureConfigurationState) {
    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: AzureConfigurationActionsTypes.AddAzureConfigurationType,
                payload: azureConfiguration
            });
            resolve(true)
        });
    }
};

export function resetAzureConfiguration() {
    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: AzureConfigurationActionsTypes.ResetAzureConfigurationType
            });
            resolve(true)
        });
    }
};
