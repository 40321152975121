import { useDispatch } from 'react-redux';
import { IsProduction } from '../utility/environment_config';
import thunk, { ThunkMiddleware, ThunkDispatch } from "redux-thunk";
import { Action, applyMiddleware, combineReducers, compose, createStore, } from "redux";

/*-- Import reducers & states --*/
import { MessageBox_Reducer, MessageBoxState } from "./reducers/MessageBox_Reducer";
import { LoadingScreen_Reducer, LoadingScreenState } from "./reducers/LoadingScreen_Reducer";
import { UserSession_Reducer, UserSessionState } from "./reducers/UserSession_Reducer";
import { DigitalAssetLibrary_Reducer } from './reducers/DigitalAssetLibrary_Reducer';
import { AzureConfiguration_Reducer, AzureConfigurationState } from "./reducers/AzureConfiguration_Reducer";

const rootReducer = combineReducers({
    MessageBox: MessageBox_Reducer,
    LoadingScreen: LoadingScreen_Reducer,
    UserSession: UserSession_Reducer,
    DigitalAssetLibrary: DigitalAssetLibrary_Reducer,
    AzureConfiguration: AzureConfiguration_Reducer,
});
export type RootState = ReturnType<typeof rootReducer>

type ReduxDispatch = ThunkDispatch<RootState, any, Action>;
export function useReduxDispatch(): ReduxDispatch {
    return useDispatch<ReduxDispatch>();
}

interface rootState extends
    MessageBoxState,
    LoadingScreenState,
    UserSessionState,
    AzureConfigurationState { }

export interface DispatchAction extends Action {
    payload: Partial<rootState>;
}

const middleware = thunk as ThunkMiddleware<RootState, DispatchAction>;

const ReduxDevTools =
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__();

let _compose = IsProduction ? applyMiddleware(middleware) : compose(applyMiddleware(middleware), ReduxDevTools);

export const store = createStore(
    rootReducer,
    _compose
);
export default { store };