import { UserSessionActionsTypes } from "../actions/UserSession_Actions";

export interface UserSessionState {
    userId: string;
    userName: string;
    fullName: string;
    defaultLocation: string;
    userRoles: string;
    grantedMenu?: string[];
    grantedApps?: string[];
    grantedLocations?: string[];
};

const initialState: UserSessionState = {
    userId: "",
    userName: "",
    fullName: "",
    defaultLocation: "",
    userRoles: "",
    grantedMenu: [],
    grantedApps: [],
    grantedLocations: []
};

export const UserSession_Reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UserSessionActionsTypes.AddUserSessionType:
            return {
                ...state,
                userId: action.payload.userId,
                userName: action.payload.userName,
                fullName: action.payload.fullName,
                defaultLocation: action.payload.defaultLocation,
                userRoles: action.payload.userRoles
            };
        case UserSessionActionsTypes.AddGrantedMenuType:
            return {
                ...state,
                grantedMenu: action.payload
            };
        case UserSessionActionsTypes.AddGrantedAppsType:
            return {
                ...state,
                grantedApps: action.payload
            };
        case UserSessionActionsTypes.AddGrantedLocationsType:
            return {
                ...state,
                grantedLocations: action.payload
            };
        case UserSessionActionsTypes.ResetUserSessionType:
            return initialState;
        default:
            return state;
    }
};