export enum DigitalAssetLybraryActionTypes {
    GetActionType = "GET_ACTIONTYPE",
    GetLibraryList = "GET_LIBRARYLIST",
}

export const getAssetLibraryData = (response: any) => ({
    type: DigitalAssetLybraryActionTypes.GetActionType,
    payload: response.data.result
});

export const getAssetLibraryList = (response: any) => ({
    type: DigitalAssetLybraryActionTypes.GetLibraryList,
    payload: response.data.result
});